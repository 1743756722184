import * as React from 'react';
import { useHistory } from 'react-router-dom';

import Result from 'antd/lib/result';
import Button from 'antd/lib/button/button';

import { RoutePaths } from '@app/utilities/RoutePathVars';

export const NotFound: React.FC = () => {
	const history = useHistory();

	return (
		<Result
			status="404"
			title="404"
			subTitle="Sorry, the page you are looking for does not exist."
			extra={(
				<Button
					type="primary"
					onClick={() => history.push(RoutePaths.login)}
				>
					Back Home
				</Button>
			)}
		/>
	);
};
